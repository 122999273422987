import React from "react"
import { useIntl, FormattedMessage } from "react-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"
import eduImg from "../images/edu.png"
import davidImg from "../images/david.jpg"
import lucasImg from "../images/lucas.jpg"
import "./about-us.css"

const AboutUsInternal = () => {
  const intl = useIntl()

  return (
    <>
      <SEO 
        title={intl.formatMessage({id: 'aboutUs.title'})} 
        keywords={[`nvotes`, `secure`, `online`, `voting`, `tools`, `software`, `system`, `election`, `poll`]} 
      />
      <article className="slim about-us">
        <h1>
          <FormattedMessage id="aboutUs.title" />
        </h1>
        <div className="ui items">
          <div className="item">
            <div className="ui tiny image">
              <img 
                src={eduImg} 
                alt={intl.formatMessage({id: 'aboutUs.edu.name'})}
              />
            </div>
            <div className="content">
              <div className="header">
                {intl.formatMessage({id: 'aboutUs.edu.name'})}
                <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/edulix">
                  <i className="twitter icon"></i>
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/edulix">
                  <i className="linkedin icon"></i>
                </a>
              </div>
              <div className="description">
                <p>
                  <FormattedMessage id="aboutUs.edu.description" />
                </p>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="ui tiny image">
              <img src={davidImg} alt={intl.formatMessage({id: 'aboutUs.david.name'})} />
            </div>
            <div className="content">
              <div className="header">
              {intl.formatMessage({id: 'aboutUs.david.name'})}
                <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/ruescasd">
                  <i className="twitter icon"></i>
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/davidruescas">
                  <i className="linkedin icon"></i>
                </a>
              </div>
              <div className="description">
                <p>
                  <FormattedMessage id="aboutUs.david.description" />
                </p>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="ui tiny image">
              <img src={lucasImg} alt={intl.formatMessage({id: 'aboutUs.lucas.name'})} />
            </div>
            <div className="content">
              <div className="ui header">
              {intl.formatMessage({id: 'aboutUs.lucas.name'})}
                <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/lucascervera">
                  <i className="twitter icon"></i>
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://es.linkedin.com/in/lucascervera">
                  <i className="linkedin icon"></i>
                </a>
              </div>
              <div className="description">
                <p>
                  <FormattedMessage id="aboutUs.lucas.description" />
                </p>
              </div>
            </div>
          </div>

        </div>
      </article>
    </>
  )
}

const AboutUsPage = () => (
  <Layout>
    <AboutUsInternal />
  </Layout>
)

export default AboutUsPage
